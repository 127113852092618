import React, { ReactNode, useEffect, useState } from 'react'
import moment from 'dayjs'
import { Table, Button, Space, Popconfirm, Switch, Tag, Image, message, SelectProps } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { PlusOutlined } from '@ant-design/icons'
import { PaginationProps } from 'antd/es/pagination'
import { copyText, formatFilterValue } from '@/utils'
import {
  createActions,
  FilterValueState,
  isMobile,
  paginationProps,
  SortOrderState,
  useAuthsSelector,
  useGetTableScrollX,
  useGetTableScrollY,
  useHasActions
} from '@/utils/hooks'
import CreateModal from './CreateModal'
import EditableNumberCell from '@/components/EditableNumberCell'
import config from '@/config'
import * as api from './api'

export const typeLabel = {
  usdt: 'USDT',
  bank: '银行卡',
  aliPay: '支付宝',
  balance: '余额',
  wechatPay: '微信支付'
}

export interface ListItem {
  _id: string
  img?: string // 收款图片
  name: string // 收款名称
  code: string // 编号
  type: keyof typeof typeLabel // 收款编码
  rate: number // 汇率 备用
  sort: number
  enable: boolean // 是否启用
  account: string // 收款账号
  currency: string // 币种
  createdAt: string
}

export const code: SelectProps['options'] = [
  {
    label: '余额',
    value: 'balance'
  },
  {
    label: '充值',
    value: 'inflow'
  },
  {
    label: '续费',
    value: 'renewal'
  },
  {
    label: '提现',
    value: 'outflow'
  }
]

const Index: React.FC = () => {
  const [list, setList] = useState<ListItem[]>([])
  const [types, setTypes] = useState<Array<keyof typeof typeLabel>>([])
  const [record, setRecord] = useState<ListItem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({})
  const [sortOrder, setSortOrder] = useState<SortOrderState>({ sort: 'ascend' })
  const [pagination, setPagination] = useState<PaginationProps>(paginationProps)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [switchLoading, setSwitchLoading] = useState<Record<string, boolean>>({})

  const auths = useAuthsSelector()
  const actions = {
    update: undefined as ReactNode,
    remove: undefined as ReactNode
  }
  const hasActions = useHasActions(Object.keys(actions))

  const { current, pageSize } = pagination
  const columns: ColumnsType<ListItem> = [
    {
      title: '名称',
      width: 160,
      fixed: 'left',
      dataIndex: 'name'
    },
    {
      title: '编号',
      width: 120,
      dataIndex: 'code',
      filters: code.map(i => ({ text: i.label, value: i.value! })),
      filteredValue: filtered.code || null,
      filterMultiple: false
    },
    {
      title: '类型',
      width: 120,
      dataIndex: 'type',
      filters: types.map(item => {
        return {
          text: typeLabel[item],
          value: item
        }
      }),
      filterMultiple: false,
      filteredValue: filtered.type || null,
      render (value: ListItem['type']) {
        return typeLabel[value]
      }
    },
    {
      title: '汇率',
      width: 100,
      dataIndex: 'rate'
    },
    {
      title: '货币',
      width: 100,
      dataIndex: 'currency'
    },
    {
      title: '收款码',
      width: 100,
      dataIndex: 'img',
      className: 'no-padding-vertical',
      render (value) {
        return value &&
          <Image
            src={`${config.cdnServer}/payment/${value}`}
            height={47}
            fallback={require('@/assets/img/placeholder.png')}
            placeholder/>
      }
    },
    {
      title: '收款账号',
      width: 320,
      dataIndex: 'account',
      render (value) {
        return <pre style={{ cursor: 'pointer' }} onClick={() => copy(value)}>{value}</pre>
      }
    },
    {
      title: '状态',
      width: 100,
      dataIndex: 'enable',
      render (text, record) {
        const status = text ? 'processing' : 'error'
        const value = text ? '启用' : '禁用'
        return auths.includes('updateEnable')
          ? <Switch
            checked={text}
            loading={switchLoading[record._id]}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onClick={() => updateEnable(record)}/>
          : <Tag color={status}>{value}</Tag>
      }
    },
    {
      title: '排序',
      width: 100,
      sorter: true,
      sortOrder: sortOrder.sort,
      dataIndex: 'sort',
      className: 'common__editable-cell',
      onCell (record) {
        return {
          title: '排序',
          value: record.sort,
          editable: true,
          handleSave (value: number) {
            const sort = Math.max(Math.min(+value, 999), 1)
            if (!isNaN(sort) && sort !== record.sort) {
              updateSort(record, sort)
            }
          }
        }
      }
    },
    {
      title: '创建时间',
      width: 164,
      dataIndex: 'createdAt',
      render (text) {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 120,
      fixed: isMobile ? undefined : 'right',
      render (text, record) {
        actions.update = (
          <a onClick={() => update(record)}>编辑</a>
        )
        actions.remove = (
          <Popconfirm
            title="你确定要删除这条记录吗？"
            placement="topRight"
            onConfirm={() => remove(record)}>
            <a>删除</a>
          </Popconfirm>
        )
        return createActions(actions, auths)
      }
    }
  ]
  !hasActions && columns.pop()
  const scrollX = useGetTableScrollX(columns)
  const scrollY = useGetTableScrollY(list.length)

  useEffect(getType, [])
  useEffect(getList, [sortOrder, current, pageSize, filtered])

  function getType () {
    api.getType().then(({ success, data }) => {
      success && setTypes(data)
    })
  }

  function getList () {
    const params = {
      sort: sortOrder,
      page: current,
      pageSize,
      ...formatFilterValue(filtered)
    }
    setLoading(true)
    api.list(params).then(({ success, data }) => {
      if (success) {
        setList(data.rows)
        setPagination(state => ({
          ...state,
          total: data.total,
          current: data.page,
          pageSize: data.pageSize
        }))
      }
      setLoading(false)
    })
  }

  function create () {
    setRecord(undefined)
    setOpenModal(true)
  }

  function update (record: ListItem) {
    setRecord(record)
    setOpenModal(true)
  }

  function createOrUpdateSuccess (data?: ListItem) {
    if (data && record) {
      Object.assign(record, { img: undefined }, data)
      setList(JSON.parse(JSON.stringify(list)))
    } else {
      getList()
    }
  }

  function updateEnable (record: ListItem) {
    const params = {
      _id: record._id,
      enable: !record.enable
    }
    setSwitchLoading({ [record._id]: true })
    api.updateByJson(params).then(({ success, data }) => {
      if (success) {
        record.enable = data.enable
        setList([...list])
      }
      setSwitchLoading({})
    })
  }

  function updateSort (record: ListItem, sort: number) {
    const params = {
      _id: record._id,
      sort
    }
    api.updateByJson(params).then(({ success }) => {
      success && getList()
    })
  }

  function remove (record: ListItem) {
    const params = { _id: record._id }
    return api.remove(params).then(({ success }) => {
      success && getList()
    })
  }

  function copy (text: string) {
    copyText(text)
    message.success('复制成功')
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters, sorter) => {
    sorter = Array.isArray(sorter) ? sorter[0] : sorter
    const sort = {
      [sorter.field as string]: sorter.order
    }
    setSortOrder(sort)
    setFiltered(filters)
  }

  return (
    <div className="common">
      {
        auths.includes('create') &&
        <Space className="common__header" wrap>
          <Button onClick={create} type="primary" icon={<PlusOutlined/>}>
            添加
          </Button>
        </Space>
      }
      <Table
        rowKey="_id"
        scroll={{ x: scrollX, y: scrollY }}
        loading={loading}
        columns={columns}
        dataSource={list}
        components={{
          body: {
            cell: EditableNumberCell
          }
        }}
        pagination={pagination}
        onChange={onChange}/>
      <CreateModal
        open={openModal}
        codes={types}
        record={record}
        onSuccess={createOrUpdateSuccess}
        onCancel={() => setOpenModal(false)}/>
    </div>
  )
}

export default Index
