import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  message as antdMessage,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Upload,
  Popconfirm
} from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import config from '@/config'
import { RcFile } from 'antd/es/upload'
import { FormInstance } from 'antd/lib'
import { Rule } from 'antd/es/form'
import { File, Target } from '@/views/telegram/types'
import { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { requiredInputRule, requiredSelectRule, useUserSelector } from '@/utils/hooks'
import { getActiveId } from '@/store/slice/account'
import { canSendMessage, validaImageFile } from '@/utils'
import { ListItem as Task } from '@/views/task'
import { ListItem as Channel } from '@/views/telegram/channel'
import { ListItem as Contact } from '@/views/telegram/contact'
import { AccessItem, SuccessTag, targetOptions } from '@/views/telegram/common'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { all as getChannel } from '@/views/telegram/channel/api'
import { all as getContact } from '@/views/telegram/contact/api'
import { uploadFile, removeFile } from '@/api/common'
import ImagePreview from '@/components/ImagePreview'
import MessageTemplate from '@/views/telegram/MessageTemplate'
import FormChannelSelect from '@/views/telegram/FormChannelSelect'
import FormContactSelect from '@/views/telegram/FormContactSelect'
import FormAccountSelect from '@/views/telegram/FormAccountSelect'
import domain from '@/config/domain'
import dayjs, { Dayjs } from 'dayjs'
import * as tg from '@/views/telegram/api'
import * as taskApi from '@/views/task/api'
import { useForm } from 'antd/es/form/Form'

interface Props {
  open: boolean
  target?: Target
  record?: Task
  targetList?: Array<AccessItem | string>
  multiAccount?: boolean
  canChangeTargetList?: boolean
  onClose: () => void
  onSuccess?: () => void
  updateSuccess?: (data: Task) => void
}

interface FormValues {
  _id: string
  loop: boolean
  files: UploadFile[]
  delay: number
  maxDelay?: number
  target: Target
  message: string[]
  interval: number
  maxInterval?: number
  delayUnit: 'seconds' | 'minute'
  intervalUnit: 'seconds' | 'minute' | 'hour' | 'day'
  time?: [Dayjs, Dayjs]
  stopDate?: string
  filterChat: boolean
  footer: 'emoji' | 'letter' | 'nothing'
  previewLink: boolean
  targetIds: string[]
}

interface DelayFormItemProps {
  min?: number
  max?: number
  width?: string
  tooltip?: string
  disabled?: boolean
  timeUnitList?: Array<'seconds' | 'minute' | 'hour'>
}

interface MultiDelayFormItemProps {
  form: FormInstance
  type?: 'delay' | 'interval'
  disabled?: boolean
}

interface TimerFormItemProps {
  form: FormInstance
  record?: Task
  disabled: boolean
  setDisabled: (disabled: boolean) => void
}

interface TargetFormItemProps {
  form: FormInstance
  open: boolean
  hidden?: boolean
  target?: Target
  account?: string
  banChange?: boolean
  onlyTarget?: boolean
  setTargetList: React.Dispatch<React.SetStateAction<(Contact | Channel)[]>>
}

interface MessageFormItemProps {
  oneMessage?: boolean
}

interface SendMessageFormItemProps {
  open: boolean
  form: FormInstance
  record?: Props['record']
}

interface TimeUnitOption extends DefaultOptionType {
  label: React.ReactNode
  value: 'seconds' | 'minute' | 'hour'
}

export const suffix = `\n\n<b>${domain.title} @${domain.telegram.split('/').pop()}</b>`
export const timeUnit: TimeUnitOption[] = [
  {
    label: '秒',
    value: 'seconds'
  },
  {
    label: '分钟',
    value: 'minute'
  },
  {
    label: '小时',
    value: 'hour'
  }
]
export const sendTypeTips = '单次发送只会发送一次消息，循环发送会按照你设置的循环间隔时间，循环发送消息，添加后可在任务管理中查看发送结果或进行更新暂停等操作'
export const delayTips = '每一条消息之间的间隔时间。如果账号有发言频率限制，可以提高单个间隔时间。可在任务管理中查看发送结果，分析频率限制情况。如果设置最大间隔时间，则是在最小间隔到最大间隔之间随机，如不设置最大间隔则是固定间隔时间'
export const intervalTips = '每一轮消息群发之间的间隔时间，如果设置最大间隔时间，则是在最小间隔到最大间隔之间随机，如不设置最大间隔则是固定间隔时间'
export const timeTips = '可自定义每天的休息时间段，在休息时间段内则停止发送消息，如果不设置则是24小时运行'
export const stopDateTips = '可设置停止日期，任务到期自动暂停'
export const filterChatTips = '每间隔1小时过滤一次不能发言的群组，同时会选中账号下所有能发言的群组，如果不希望发送所有群组，只需要发送指定群组，请关闭此选项'

export const initialValues: Partial<FormValues> = {
  loop: false,
  files: [],
  delay: 10,
  maxDelay: 60,
  interval: 5,
  // maxInterval: 10,
  delayUnit: 'seconds',
  intervalUnit: 'minute',
  targetIds: [],
  previewLink: true,
  filterChat: true
}

const _DelayFormItem: React.FC<DelayFormItemProps> = (props) => {
  const { min = 0, max = 60, width = '100px', tooltip, disabled, timeUnitList = [] } = props
  const _timeUnit = timeUnit.filter(item => timeUnitList.includes(item.value))

  return (
    <Form.Item
      label="单个间隔"
      tooltip={tooltip}
      required>
      <Space.Compact block>
        <Form.Item name="delay" label="时间" rules={requiredInputRule} noStyle>
          <InputNumber
            min={min}
            max={max}
            style={{ width: `calc(100% - ${width})` }}
            pattern="\d*"
            precision={0}
            disabled={disabled}
            placeholder={'请输入'}
          />
        </Form.Item>
        <Form.Item name="delayUnit" label="时间单位" rules={requiredInputRule} noStyle>
          <Select
            style={{ width }}
            options={_timeUnit}
            disabled={disabled}
            placeholder={'请选择'}/>
        </Form.Item>
      </Space.Compact>
    </Form.Item>
  )
}

const _MultiDelayFormItem: React.FC<MultiDelayFormItemProps> = (props) => {
  const { form, type = 'delay', disabled } = props

  const key = type === 'delay'
    ? {
      min: 'delay',
      max: 'maxDelay',
      unit: 'delayUnit'
    }
    : {
      min: 'interval',
      max: 'maxInterval',
      unit: 'intervalUnit'
    }
  const label = type === 'delay' ? '单个间隔' : '循环间隔'
  const options = type === 'delay' ? timeUnit.slice(0, 2) : timeUnit.slice(1, 3)
  const tooltip = type === 'delay' ? delayTips : intervalTips
  const maxRule: Rule[] = [
    {
      message: '最大间隔应大于最小间隔',
      validator (rule, value) {
        const min = form.getFieldValue(key.min)
        if (!value || value > min) return Promise.resolve()
        return Promise.reject()
      }
    }
  ]

  return (
    <Form.Item
      label={label}
      tooltip={tooltip}
      required>
      <Space.Compact block>
        <Form.Item name={key.min} label="最小间隔" rules={requiredInputRule} noStyle>
          <InputNumber
            min={0}
            max={100}
            style={{ flex: 'auto' }}
            pattern="\d*"
            disabled={disabled}
            precision={0}
            placeholder={'最小间隔'}/>
        </Form.Item>
        <Form.Item noStyle>
          <Input
            style={{
              flex: '0 0 30px',
              padding: '0',
              textAlign: 'center',
              pointerEvents: 'none'
            }}
            disabled={disabled}
            placeholder="~"/>
        </Form.Item>
        <Form.Item name={key.max} label="最大间隔" rules={maxRule} noStyle>
          <InputNumber
            min={1}
            max={100}
            style={{ flex: 'auto' }}
            pattern="\d*"
            disabled={disabled}
            precision={0}
            placeholder={'最大间隔'}/>
        </Form.Item>
        <Form.Item name={key.unit} label="时间单位" rules={requiredInputRule} noStyle>
          <Select
            style={{ flex: '0 0 100px' }}
            options={options}
            disabled={disabled}
            placeholder={'请选择'}/>
        </Form.Item>
      </Space.Compact>
    </Form.Item>
  )
}

const _TimerFormItem: React.FC<TimerFormItemProps> = (props) => {
  const { form, record, disabled, setDisabled } = props
  const initTime = [
    dayjs().set('hours', 5).set('minutes', 0).set('seconds', 0),
    dayjs().set('hours', 9).set('minutes', 0).set('seconds', 0)
  ]

  return (
    <Row gutter={20}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Form.Item
          name="loop"
          label="发送方式"
          rules={requiredInputRule}
          tooltip={sendTypeTips}>
          <Radio.Group
            disabled={!!record}
            onChange={e => setDisabled(!e.target.value)}>
            <Radio value={false}>单次发送</Radio>
            <Radio value={true}>循环发送</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Form.Item
          name="filterChat"
          label="定时过滤群组"
          rules={requiredInputRule}
          tooltip={filterChatTips}>
          <Radio.Group disabled={disabled}>
            <Radio value={true}>开启</Radio>
            <Radio value={false}>关闭</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <MultiDelayFormItem form={form}/>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <MultiDelayFormItem type={'interval'} form={form} disabled={disabled}/>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Form.Item
          name="time"
          label="休息时间段"
          tooltip={timeTips}
          // initialValue={initTime}
        >
          <TimePicker.RangePicker disabled={disabled} allowClear inputReadOnly/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Form.Item name="stopDate" label="停止日期" tooltip={stopDateTips}>
          <DatePicker
            showTime
            showNow={false}
            disabled={disabled}
            allowClear
            disabledDate={date => date.valueOf() < Date.now()}
            inputReadOnly/>
        </Form.Item>
      </Col>
    </Row>
  )
}

const _TargetFormItem: React.FC<TargetFormItemProps> = (props) => {
  const { open, form, target: targetProps, account, onlyTarget, hidden, banChange, setTargetList } = props
  const [target, setTarget] = useState<Target | undefined>()
  const [contact, setContact] = useState<Contact[]>([])
  const [channel, setChannel] = useState<Channel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const extra = useMemo(() => {
    return target === 'peerUser'
      ? '发送目标为选中账号下的所有好友，自动过滤已注销的好友'
      : '发送目标为选中账号下的所有群组，自动过滤不能发言的群组'
  }, [target])
  const tooltip = useMemo(() => {
    return target === 'peerUser'
      ? '已去除账号注销的好友'
      : '已去除禁止发言的群组'
  }, [target])
  const contactFiltered = useMemo(() => contact.filter(i => !i.deleted), [contact])
  const channelFiltered = useMemo(() => channel.filter(canSendMessage), [channel])
  const targetList = useMemo(getTargetList, [target, channelFiltered, contactFiltered])

  const [_form] = useForm()

  useEffect(getList, [open, target, account, hidden, onlyTarget])
  useEffect(setDefaultTarget, [open, targetProps])
  useEffect(setTargetListFn, [target, contactFiltered, channelFiltered, setTargetList])

  // 获取下拉列表
  function getList () {
    if (!open || !target || !account || hidden || onlyTarget) return
    const params = {
      account
    }
    const apiFn = target === 'peerUser' ? _getContact : _getChannel

    setLoading(true)
    apiFn(params).finally(() => setLoading(false))

    return () => {
      setContact([])
      setChannel([])
    }
  }

  // 获取好友
  function _getContact (params: Record<string, any>) {
    return getContact(params).then(({ data, success }) => {
      success && setContact(data)
    })
  }

  // 获取群组
  function _getChannel (params: Record<string, any>) {
    return getChannel(params).then(({ data, success }) => {
      success && setChannel(data)
    })
  }

  // 设置默认发送目标
  function setDefaultTarget () {
    open && setTarget(targetProps)

    return () => setTarget(undefined)
  }

  // 发送目标传递给父组件
  function setTargetListFn () {
    setTargetList(target === 'peerUser' ? contactFiltered : channelFiltered)
  }

  function getTargetList () {
    if (target === 'peerUser') {
      return contactFiltered
    } else if (target === 'peerChannel') {
      return channelFiltered
    }
    return []
  }

  function selectAll () {
    form.setFieldValue('targetIds', targetList.map(i => i.id))
  }

  // 自定义数量
  function custom (values: Record<string, any>) {
    let { min = 0, max } = values
    if (!min && !max) return
    min = min === 1 ? 0 : min
    form.setFieldValue('targetIds', targetList.slice(min, max || undefined).map(i => i.id))
  }

  function targetOnchange (value: Target) {
    setTarget(value)
    form.setFieldValue('target', value)
    form.setFieldValue('targetIds', [])
  }

  return (
    <>
      <Form.Item name={'target'} rules={requiredSelectRule} hidden>
        <Input type={'hidden'}/>
      </Form.Item>
      {
        !hidden &&
        (
          onlyTarget
            ? <Row>
              <Col span={12}>
                <Form.Item label={'发送目标'} required tooltip={extra}>
                  <Radio.Group
                    value={target}
                    options={targetOptions}
                    disabled={banChange}
                    onChange={e => targetOnchange(e.target.value)}/>
                </Form.Item>
              </Col>
              {/* <Col span={12}> */}
              {/*   <Form.Item label="账号间隔" tooltip={'避免多个账号同一时间发送消息'} required> */}
              {/*     <Space.Compact block> */}
              {/*       <Form.Item name="accountDelay" label="时间" rules={requiredInputRule} noStyle initialValue={1}> */}
              {/*         <InputNumber */}
              {/*           min={0} */}
              {/*           max={60} */}
              {/*           style={{ width: '50%' }} */}
              {/*           pattern="\d*" */}
              {/*           precision={0} */}
              {/*           placeholder={'请输入'} */}
              {/*         /> */}
              {/*       </Form.Item> */}
              {/*       <Form.Item name="accountDelayUnit" label="时间单位" rules={requiredInputRule} noStyle */}
              {/*         initialValue={'minute'}> */}
              {/*         <Select */}
              {/*           style={{ width: '50%' }} */}
              {/*           options={timeUnit.slice(1, 2)} */}
              {/*           placeholder={'请选择'}/> */}
              {/*       </Form.Item> */}
              {/*     </Space.Compact> */}
              {/*   </Form.Item> */}
              {/* </Col> */}
            </Row>
            : <Form.Item
              label={
                <Space>
                  <span>发送目标</span>
                  <a onClick={selectAll}>全选</a>
                  <Popconfirm
                    title={`共${targetList.length}个`}
                    description={
                      <Form form={_form} style={{ width: 200 }} onFinish={custom}>
                        <Space.Compact block>
                          <Form.Item name={'min'} noStyle>
                            <InputNumber
                              min={1}
                              max={targetList.length}
                              style={{ flex: 'auto' }}
                              pattern="\d*"
                              precision={0}
                              placeholder={'1'}/>
                          </Form.Item>
                          <Form.Item noStyle>
                            <Input
                              style={{
                                flex: '0 0 30px',
                                padding: '0',
                                textAlign: 'center',
                                pointerEvents: 'none'
                              }}
                              placeholder="~"/>
                          </Form.Item>
                          <Form.Item name={'max'} noStyle>
                            <InputNumber
                              min={2}
                              max={targetList.length}
                              style={{ flex: 'auto' }}
                              pattern="\d*"
                              precision={0}
                              placeholder={String(targetList.length)}/>
                          </Form.Item>
                        </Space.Compact>
                      </Form>
                    }
                    onConfirm={_form.submit}>
                    <a>自选数量</a>
                  </Popconfirm>
                </Space>
              }
              required
              tooltip={tooltip}>
              <Space.Compact block>
                <Form.Item noStyle>
                  <Select
                    style={{ width: 74 }}
                    value={target}
                    options={targetOptions}
                    disabled={banChange}
                    onChange={targetOnchange}
                  />
                </Form.Item>
                {
                  target === 'peerUser' &&
                  <Form.Item
                    name={'targetIds'}
                    label={'发送目标'}
                    rules={requiredSelectRule}
                    noStyle>
                    <FormContactSelect
                      open={open}
                      list={contactFiltered}
                      mode={'multiple'}
                      style={{ width: 'calc(100% - 74px)' }}
                      filter={'sendMessage'}
                      loading={loading}
                    />
                  </Form.Item>
                }
                {
                  target === 'peerChannel' &&
                  <Form.Item
                    name={'targetIds'}
                    label={'发送目标'}
                    rules={requiredSelectRule}
                    noStyle>
                    <FormChannelSelect
                      open={open}
                      list={channelFiltered}
                      mode={'multiple'}
                      style={{ width: 'calc(100% - 74px)' }}
                      filter={'sendMessage'}
                      loading={loading}
                    />
                  </Form.Item>
                }
              </Space.Compact>
            </Form.Item>
        )
      }
    </>
  )
}

const _MessageFormItem: React.FC<MessageFormItemProps> = (props) => {
  const { oneMessage } = props
  const [open, setOpen] = useState(false)
  const label = <div>
    <span>消息内容&nbsp;</span>
    <a onClick={() => setOpen(true)}>消息美化示例</a>
  </div>

  return (
    <>
      {
        oneMessage
          ? <Form.Item name="message" label={label} tooltip={'多条消息时将随机发送其中一条'} rules={requiredInputRule}>
            <Input.TextArea
              rows={8}
              showCount
              allowClear
              maxLength={4090}
              placeholder="请输入"/>
          </Form.Item>
          : <Form.List
            name="message"
            initialValue={['']}
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('至少需要输入一条消息内容'))
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? label : ''}
                    tooltip={index === 0 ? '多条消息时将随机发送其中一条' : ''}
                    required
                    key={field.key}>
                    <Form.Item
                      {...field}
                      rules={requiredInputRule}
                      noStyle>
                      <Input.TextArea
                        rows={4}
                        showCount
                        allowClear
                        maxLength={4090}
                        placeholder="请输入"/>
                    </Form.Item>
                    {
                      fields.length > 1
                        ? <MinusCircleOutlined
                          style={{ fontSize: '16px' }}
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}/>
                        : null
                    }
                  </Form.Item>
                ))}
                {
                  fields.length < 10 &&
                  <Form.Item>
                    <Button type="dashed" icon={<PlusOutlined/>} block onClick={() => add()}>
                      添加一条消息
                    </Button>
                  </Form.Item>
                }
              </>
            )}
          </Form.List>
      }
      <MessageTemplate open={open} onCancel={() => setOpen(false)}/>
    </>
  )
}

const _SendMessageFormItem: React.FC<SendMessageFormItemProps> = (props) => {
  const { open, form, record } = props
  const [previewUrl, setPreviewUrl] = useState<string>()

  const filesRules: Rule[] = [
    {
      validator (rule, value) {
        const fileList: UploadFile[] = value || []

        const promises = fileList.map((item: UploadFile) => {
          if (item.status === 'success') return Promise.resolve()
          return validaImageFile(item.originFileObj!)
        })

        return Promise.all(promises)
      }
    }
  ]

  useEffect(toggleVisible, [open, form, record])

  function toggleVisible () {
    if (open && record) {
      const photo = record.params.photo || []
      const fileList = photo.map(item => ({
        name: item.filename,
        uid: item.filename,
        url: config.media + item.filename,
        status: 'success'
      }))
      form.setFieldValue('files', fileList)
    }
  }

  // 图片预览
  function onPreview (file: UploadFile) {
    let url: string
    if (file.status === 'success') {
      url = file.url!
    } else {
      url = URL.createObjectURL(file.originFileObj as Blob)
    }

    setPreviewUrl(url)
  }

  // 上传文件，上传多张到tg，已禁用，因为图片无法持久保存，多账号时无法共用
  // const customRequest: UploadProps['customRequest'] = (options) => {
  //   validaImageFile(options.file as RcFile).then(() => {
  //     const params = {
  //       _id: form.getFieldValue('_id'),
  //       file: options.file as RcFile
  //     }
  //     const config: AxiosRequestConfig = {
  //       onUploadProgress: options.onProgress
  //     }
  //
  //     tg.uploadMedia<Photo>(params, config).then(({ success, data, message }) => {
  //       if (success) {
  //         options.onSuccess!(success)
  //         setFileDataList(state => ({
  //           ...state,
  //           [params.file.uid]: data
  //         }))
  //       } else {
  //         return Promise.reject(new Error(message))
  //       }
  //     })
  //   }).catch(options.onError)
  // }
  const customRequest: UploadProps['customRequest'] = (options) => {
    validaImageFile(options.file as RcFile).then(() => {
      options.onSuccess!(null)
    }).catch(options.onError)
  }

  return (
    <>
      <Form.Item
        name={'files'}
        label="消息图片"
        rules={filesRules}
        tooltip="请上传JPG/PNG图片，并且不大于512KB"
        valuePropName="fileList"
        getValueFromEvent={e => e?.fileList}>
        <Upload
          customRequest={customRequest}
          accept="image/png,image/jpeg,image/gif"
          maxCount={1}
          listType="picture-card"
          onPreview={onPreview}>
          <div>
            <PlusOutlined/>
            <div>选择图片</div>
          </div>
        </Upload>
      </Form.Item>
      <div style={{ display: 'none' }}>
        <ImagePreview url={previewUrl} setUrl={setPreviewUrl}/>
      </div>
      <MessageFormItem/>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="previewLink"
            label="链接预览"
            rules={requiredInputRule}
            tooltip={'如果消息中包含链接，是否显示链接预览'}>
            <Radio.Group>
              <Radio value={true}>开启</Radio>
              <Radio value={false}>关闭</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="footer"
            rules={requiredInputRule}
            label="消息后缀"
            tooltip={'在消息末尾插入随机表情或随机字母，避免消息重复一致'}
            initialValue={'emoji'}>
            <Radio.Group>
              <Radio value={'emoji'}>表情</Radio>
              <Radio value={'letter'}>字母</Radio>
              <Radio value={'nothing'}>关闭</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const DelayFormItem = memo(_DelayFormItem)
const TimerFormItem = memo(_TimerFormItem)
const TargetFormItem = memo(_TargetFormItem)
const MessageFormItem = memo(_MessageFormItem)
const SendMessageFormItem = memo(_SendMessageFormItem)
const MultiDelayFormItem = memo(_MultiDelayFormItem)

const Index: React.FC<Props> = (props) => {
  const {
    open = false,
    target = 'peerChannel',
    record,
    targetList,
    multiAccount,
    canChangeTargetList = false,
    onClose,
    onSuccess,
    updateSuccess
  } = props

  const [list, setList] = useState<Array<Contact | Channel>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(true)
  // const [fileDataList, setFileDataList] = useState<FileData>({})

  const user = useUserSelector()
  const activeId = useSelector(getActiveId)
  const allTargetList = useMemo(getAllTargetList, [list, record])

  const [form] = Form.useForm()

  useEffect(toggleVisible, [open, form, record, target, activeId, targetList, multiAccount])

  function toggleVisible () {
    if (open && record) {
      const { params } = record
      form.setFieldsValue({
        ...params,
        time: params.time?.length ? [dayjs(params.time[0]), dayjs(params.time[1])] : params.time,
        message: params.message.map(i => i.replace(suffix, '')),
        stopDate: params.stopDate && dayjs(params.stopDate),
        targetIds: params.targetList.map((i: AccessItem) => i.id)
      })
      setDisabled(!params.loop)
    } else if (open) {
      form.setFieldsValue({
        _id: multiAccount && activeId ? [activeId] : activeId,
        target,
        footer: targetList?.length === 1 ? 'nothing' : 'emoji',
        targetIds: targetList?.map(i => typeof i === 'string' ? i : i.id)
      })
      setDisabled(true)
    } else {
      // setFileDataList({})
    }
  }

  // 删除文件
  // const onRemove: UploadProps['onRemove'] = (file) => {
  //   setFileDataList(state => ({
  //     ...state,
  //     [file.uid]: null
  //   }))
  // }

  // 将任务记录中的发送目标和当前目标列表合并，防止发送目标中有非好友，不能编辑
  function getAllTargetList () {
    if (record?.params.target === 'peerUser') {
      const targetList = record.params.targetList.filter(item => !list.some(i => i.id === item.id))
      return [...list, ...targetList]
    } else {
      return list
    }
  }

  function upload (file?: UploadFile) {
    const params = {
      file: file?.originFileObj
    }

    if (file?.status !== 'success' && record?.params.photo?.length) {
      removeFile({ photo: record.params.photo })
    }
    if (!file) return Promise.resolve([])
    if (file.status === 'success') return Promise.resolve(null)

    return uploadFile<File[]>(params).then(({ success, data, message }) => {
      if (success) return data
      return Promise.reject(message)
    })
  }

  function onFinish (values: FormValues) {
    const { targetIds = [], files, ...rest } = values
    // const photo = Object.values(fileDataList).filter(Boolean).map(item => ({
    //   id: item!.id,
    //   access_hash: item!.access_hash,
    //   file_reference: item!.file_reference,
    //   thumb_size: (item as SavePhoto).thumb_size || (item as Photo).sizes.filter(i => (i._ = 'photoSize')).pop()!.type
    // }))

    const params = {
      ...record?.params,
      ...rest,
      // photo,
      targetList: canChangeTargetList
        ? allTargetList.filter(i => targetIds.includes(i.id)).map(i => ({
          id: i.id,
          access_hash: i.access_hash
        }))
        : targetList
    }
    const execType = !record && !multiAccount && !params.loop && params.targetList?.length === 1 && 'online'

    if (user.role.type === 'user') params.message = params.message.map(i => i + suffix)
    if (!params.targetList?.length && !multiAccount) return

    setLoading(true)

    const apiFn = execType === 'online' ? online : task

    upload(files[0]).then(files => {
      if (files) params.photo = files
      return apiFn(params)
    }).then(success => {
      if (success) {
        onClose()
      }
    }).finally(() => setLoading(false))

    function online (params: Record<string, any>) {
      const apiFn = params.photo.length
        ? tg.sendMedia
        : tg.sendMessage
      return apiFn<SuccessTag[]>(params).then(({ data, success }) => {
        if (success) {
          onSuccess?.()
          antdMessage.success(`成功发送 ${data.filter(i => /^\d+$/.test(String(i))).length} 条消息`)
        }
        return success
      })
    }

    function task (params: Record<string, any>) {
      return record ? update() : add()

      function add () {
        const task: Record<string, any> = {
          action: params.photo?.length ? 'sendMedia' : 'sendMessage',
          params: {
            ...params,
            target: params.target
          }
        }
        const apiFn = multiAccount ? taskApi.createSendTasks : taskApi.create

        return apiFn(task).then(({ data, success }) => {
          if (success) {
            onSuccess?.()
            antdMessage.success('任务添加成功')
          }
          return success
        })
      }

      function update () {
        const update = {
          _id: record!._id,
          action: params.photo?.length ? 'sendMedia' : 'sendMessage',
          params
        }
        return taskApi.update(update).then(({ data, success, message }) => {
          if (success) {
            antdMessage.success(message)
            updateSuccess?.(data)
          }
          return success
        })
      }
    }
  }

  const DrawerFooter = <Space>
    <Button onClick={onClose}>取消</Button>
    <Button
      type={'primary'}
      loading={loading}
      disabled={record?.status === 'error'}
      onClick={form.submit}>提交</Button>
  </Space>

  return (
    <Drawer
      title={`发送消息${targetList?.length ? ` - ${targetList!.length}个目标` : ''}`}
      open={open}
      closable={false}
      placement="right"
      rootClassName={'drawer-720'}
      footerStyle={{ textAlign: 'right' }}
      forceRender
      footer={DrawerFooter}
      onClose={onClose}
      afterOpenChange={v => !v && form.resetFields()}>
      <Form
        name="send"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center'
        }}
        onFinish={onFinish}>
        <FormAccountSelect form={form} hidden={!multiAccount} showNoTask/>
        <TargetFormItem
          open={open}
          form={form}
          hidden={!canChangeTargetList}
          target={record?.params.target || target}
          account={record?.params._id || activeId}
          banChange={!!record}
          onlyTarget={multiAccount}
          setTargetList={setList}
        />
        {/* <Form.Item
         label="消息图片"
         extra="请上传JPG/PNG图片，并且不大于512KB，最多可上传10张"
         tooltip={'此处上传的图片只会保存一段时间，如果需要循环发送图片消息，请使用转发消息'}>
         <Upload
         customRequest={customRequest}
         accept="image/png,image/jpeg,image/gif"
         maxCount={10}
         multiple
         fileList={fileList}
         listType="picture-card"
         onChange={info => setFileList(info.fileList)}
         onRemove={onRemove}
         onPreview={onPreview}>
         <div>
         <PlusOutlined/>
         <div>选择图片</div>
         </div>
         </Upload>
         </Form.Item> */}
        <SendMessageFormItem open={open} form={form} record={record}/>
        <TimerFormItem
          form={form}
          record={record}
          disabled={disabled}
          setDisabled={setDisabled}/>
      </Form>
    </Drawer>
  )
}

export { TimerFormItem, TargetFormItem, MessageFormItem, DelayFormItem, SendMessageFormItem, MultiDelayFormItem }
export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
