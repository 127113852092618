import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 列表
export function all<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/all'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 列表
export function showList<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/showList'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function removes<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/removes'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function testConnection<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/proxyServer/testConnection'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
