import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { Rule } from 'antd/es/form'
import { FormInstance } from 'antd/lib'
import { Modal, Form, Input, Radio, InputNumber, Select, message, Row, Col, Space, DatePicker } from 'antd'
import { isMobile, requiredInputRule, requiredSelectRule } from '@/utils/hooks'
import { DelayFormItem, timeUnit } from '@/views/telegram/SendMessage'
import { resultModal, ResultItem } from '@/views/telegram/common'
import { useSelector } from 'react-redux'
import { getActiveId, getOptions } from '@/store/slice/account'
import { usernameRegex } from '@/views/telegram/account/UpdateForm'
import FormAccountSelect from '@/views/telegram/FormAccountSelect'
import * as tg from '@/views/telegram/api'
import * as taskApi from '@/views/task/api'

interface Props {
  open: boolean
  chatType?: 'public' | 'private'
  username?: string[]
  onSuccess: () => void
  onCancel: () => void
}

interface FormValues {
  _id: string[]
  type: 'public' | 'private'
  delay: number
  delayUnit: string
  hash: string
  username: string
  autoSend: boolean
  nextExecTime?: number
  accountDelay: number
  splitEqually: boolean
  accountDelayUnit: string
}

interface UsernameFormItemProps {
  max: number
  form: FormInstance
  label: string
  showFilterText?: boolean
}

const keyMap = {
  public: 'username',
  private: 'hash'
}

const initialValues: Partial<FormValues> = {
  type: 'public',
  delay: 1,
  delayUnit: 'minute',
  autoSend: false,
  accountDelay: 1,
  splitEqually: false,
  accountDelayUnit: 'minute'
}

const UsernameFormItem: React.FC<UsernameFormItemProps> = (props) => {
  const { max, form, label, showFilterText = true } = props
  const tooltip = <div>
    <span>{showFilterText ? '自动过滤已添加，' : ''}支持以下格式：</span>
    <pre>
      <span>username</span>
      <br/>
      <span>@username</span>
      <br/>
      <span>https://t.me/username</span>
    </pre>
  </div>
  const usernameRule: Rule[] = [
    ...requiredInputRule,
    {
      message: `单次最多输入${max}个${label}`,
      validator (_, value) {
        const length = value.split('\n').length
        if (length > max) return Promise.reject()
        return Promise.resolve()
      }
    }
  ]

  function usernameOnBlur (e: ChangeEvent<HTMLTextAreaElement>) {
    let value: string | string[] = e.target.value
    // 分割符号，替换成换行
    value = value.replace(/[,，\s\n]/g, '\n')
    // 去除用户名前缀
    value = value.replace(/@|https:|t\.me|\\/ig, '')
    // 去除出字母数字下划线外的字符
    value = value.replace(/[^a-zA-Z0-9_\n]/g, '')
    // 去除空值和不正确的用户名
    value = value.split('\n').filter(Boolean).filter(i => usernameRegex.regex.test(i))
    // 去重
    value = [...Array.from(new Set(value))].join('\n')
    form.setFieldValue('username', value)
  }

  return (
    <Form.Item
      name="username"
      label={label}
      rules={usernameRule}
      tooltip={tooltip}
      validateFirst>
      <Input.TextArea
        rows={5}
        placeholder={`请输入${label}，多个请用空格/逗号/换行分隔`}
        onBlur={usernameOnBlur}/>
    </Form.Item>
  )
}

const Index: React.FC<Props> = (props) => {
  const { open = false, chatType, username, onSuccess, onCancel } = props
  const [type, setType] = useState<FormValues['type']>('public')
  const [loading, setLoading] = useState<boolean>(false)

  const options = useSelector(getOptions)
  const activeId = useSelector(getActiveId)

  const [form] = Form.useForm()
  const usernameRule: Rule[] = [
    ...requiredInputRule,
    {
      message: '单次最多添加500个群组',
      validator (_, value) {
        const length = value.split('\n').length
        if (length > 500) return Promise.reject()
        return Promise.resolve()
      }
    }
  ]

  useEffect(toggleVisible, [open, chatType, username, form, activeId])

  // 设置默认值
  function toggleVisible () {
    const _chatType = chatType || 'public'
    if (open) {
      setType(_chatType)
      form.setFieldsValue({
        _id: activeId && [activeId],
        type: _chatType,
        [keyMap[_chatType]]: username?.join('\n')
      })
    }
  }

  function hashOnBlur (e: ChangeEvent<HTMLTextAreaElement>) {
    let value: string | string[] = e.target.value
    const hashRegex = /^\+[A-z0-9_-]{16}$/
    // 分割符号，替换成换行
    value = value.replace(/[,，\s\n]/g, '\n')
    // 去除用户名前缀
    value = value.replace(/@|https:|t\.me|\\/ig, '')
    // 去除出字母数字下划线+号外的字符
    value = value.replace(/[^a-zA-Z0-9_+\-\n]/g, '')
    // 去除空值和不正确的用户名
    value = value.split('\n').filter(Boolean).filter(i => hashRegex.test(i))
    // 去重
    value = [...Array.from(new Set(value))].join('\n')
    form.setFieldValue('hash', value)
  }

  function onFinish (values: FormValues) {
    const { type, hash, username, nextExecTime, splitEqually, ...rest } = values
    const _hash = hash?.split('\n')
    const _username = username?.split('\n')
    const targetList = type === 'public' ? _username : _hash
    const params = {
      ...rest,
      targetList
    }

    if (!targetList?.length) return

    setLoading(true)

    if (targetList.length === 1) {
      online()
    } else {
      task()
    }

    function task () {
      const apiFn = splitEqually ? taskApi.createSplitTasks : taskApi.create
      const task = {
        action: type === 'public' ? 'joinChannelsByUsername' : 'importChatInvite',
        params,
        nextExecTime: nextExecTime ? nextExecTime.valueOf() : undefined
      }
      return apiFn(task).then(({ success }) => {
        if (success) {
          onCancel()
          message.success('任务添加成功')
        }
      }).finally(() => setLoading(false))
    }

    function online () {
      const apiFn = type === 'public' ? tg.joinChannelsByUsername : tg.importChatInvite
      return apiFn(params)
        .then(({ success, data }) => {
          if (success) {
            onCancel()
            onSuccess()
            const accounts = options.filter(item => params._id.includes(item.value as string))
            const resultList: ResultItem[] = accounts.map((item, index) => ({
              label: item.label,
              value: data[index] as string[]
            }))
            resultModal(resultList)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Modal
      open={open}
      title={'添加群组'}
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={onCancel}>
      <Form
        name="add"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}>
        <FormAccountSelect form={form}/>
        <Row gutter={10}>
          <Col span={12}>
            <DelayFormItem
              min={1}
              max={10}
              width={'50%'}
              timeUnitList={['minute']}
            />
          </Col>
          <Col span={12}>
            <Form.Item label="账号间隔" tooltip={'避免多个账号同一时间加入相同的群'} required>
              <Space.Compact block>
                <Form.Item name="accountDelay" label="时间" rules={requiredInputRule} noStyle>
                  <InputNumber
                    min={0}
                    max={60}
                    style={{ width: '50%' }}
                    pattern="\d*"
                    precision={0}
                    placeholder={'请输入'}
                  />
                </Form.Item>
                <Form.Item name="accountDelayUnit" label="时间单位" rules={requiredInputRule} noStyle>
                  <Select
                    style={{ width: '50%' }}
                    options={timeUnit.slice(1, 2)}
                    placeholder={'请选择'}/>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item */}
        {/*   name={'autoSend'} */}
        {/*   label={'边加边发'} */}
        {/*   rules={requiredSelectRule} */}
        {/*   tooltip={'加群账号有发送消息的任务时，自动将新加入的群组添加到发送目标中'}> */}
        {/*   <Radio.Group> */}
        {/*     <Radio value={true}>开启</Radio> */}
        {/*     <Radio value={false}>关闭</Radio> */}
        {/*   </Radio.Group> */}
        {/* </Form.Item> */}
        <Row gutter={10}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={'type'}
              label={'群组类型'}
              rules={requiredSelectRule}
            >
              <Radio.Group
                disabled={!!chatType}
                onChange={e => setType(e.target.value)}>
                <Radio value={'public'}>公开</Radio>
                <Radio value={'private'}>私有</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="splitEqually"
              rules={requiredInputRule}
              label="分配方式"
              tooltip={'选中全部，所有选中账号都将添加全部群组，选中平分，选中账号将平分需添加的群组'}>
              <Radio.Group>
                <Radio value={false}>全部</Radio>
                <Radio value={true}>平分</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {
          type === 'public'
            ? <UsernameFormItem max={20000} form={form} label={'群组链接'}/>
            : <Form.Item
              name="hash"
              label="邀请链接"
              rules={usernameRule}
              tooltip={
                <div>
                  <span>自动过滤已添加，格式如下：</span>
                  <pre>
                    <span>+BVsizQzxMh44Mzg9</span>
                    <br/>
                    <span>https://t.me/+BVsizQzxMh44Mzg9</span>
                  </pre>
                </div>
              }
              validateFirst>
              <Input.TextArea
                rows={5}
                placeholder={'请输入邀请链接，多个请用空格/逗号/换行分隔'}
                onBlur={hashOnBlur}
              />
            </Form.Item>
        }
        <Form.Item
          name={'nextExecTime'}
          label={'开始时间'}
          tooltip={'默认为任务创建后立刻开始'}>
          <DatePicker
            showTime
            showNow={false}
            allowClear
            disabledDate={date => date.valueOf() < Date.now()}
            inputReadOnly/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export { UsernameFormItem }
export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
